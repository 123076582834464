'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

export default function NotFound() {
  const currentPath = usePathname();

  const handlePath = () => {
    const basePaths = ['/servicios', '/eventos', '/descargables'];

    if (basePaths.some((path) => currentPath.includes(path))) {
      return currentPath.split('/').slice(0, 2).join('/');
    }

    return '/';
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md text-center">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Página no encontrada</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Lo sentimos, la página que buscas no existe o ha sido movida.
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Página no encontrada</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>La página que buscas no está disponible.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link href={handlePath()} className="font-medium text-indigo-600 hover:text-indigo-500">
              Volver a la página de inicio
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
